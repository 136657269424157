import { render, staticRenderFns } from "./Tile.vue?vue&type=template&id=6c359afe&scoped=true&"
import script from "./Tile.vue?vue&type=script&lang=js&"
export * from "./Tile.vue?vue&type=script&lang=js&"
import style0 from "./Tile.vue?vue&type=style&index=0&id=6c359afe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c359afe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/pbk/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6c359afe')) {
      api.createRecord('6c359afe', component.options)
    } else {
      api.reload('6c359afe', component.options)
    }
    module.hot.accept("./Tile.vue?vue&type=template&id=6c359afe&scoped=true&", function () {
      api.rerender('6c359afe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/Tile/Tile.vue"
export default component.exports